import React from 'react';
import './Footer.css'; // Archivo CSS separado para estilos

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <nav className="footer-links">
                    <a href="#about">Acerca de</a>
                    <a href="#services">Servicios</a>
                    <a href="#contact">Contacto</a>
                </nav>
                <p>&copy; {new Date().getFullYear()} NIVIRUM CLUB. Todos los derechos de propiedad intelectual y activos relacionados con Ashes of Creation pertenecen a Intrepid Studios. Hecho con ❤️ para la comunidad NIVIRUM CLUB. Este sitio es un trabajo en progreso y está en constante evolución.</p>
            </div>
        </footer>
    );
};

export default Footer;
