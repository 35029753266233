import React from 'react';
import './PublicGuildInformation.css'; // Archivo CSS para estilos personalizados.

const PublicGuildInformation = () => {

  const teamMembers = [
    { name: 'Crosis', role: 'Líder', img: './publicguildinformation/logocrosis.jpg' },
    { name: 'Sherkus', role: 'Gerente', img: './publicguildinformation/logosherkus.png' },
    { name: 'Anaxágoras', role: 'Gerente', img: './publicguildinformation/logoanaxagoras.png' },
    { name: 'Sark', role: 'Gerente', img: './publicguildinformation/logosark.png' },
    { name: 'Shinaso', role: 'Gerente', img: './publicguildinformation/logoshinaso.png' },
    { name: 'Vyro', role: 'Gerente', img: './publicguildinformation/logovyro.png' },
    { name: 'Arnelas', role: 'Gerente', img: './publicguildinformation/logoarnelas.png' },
    { name: 'Cheeko', role: 'Gerente', img: './publicguildinformation/logocheeko.png' },
    { name: 'EXTrancos', role: 'Gerente', img: './publicguildinformation/logoextrancos.jpg' },
    { name: 'kaNu', role: 'Gerente', img: './publicguildinformation/logokanu.png' },
    { name: 'Revox', role: 'Gerente', img: './publicguildinformation/logorevox.jpg' }
  ];

  return (

    <div>
      {/* Sección de Equipo de Administración */}
      <div className="team-section">
        <h2>Equipo de Administración</h2>
        <div className="team-cards">
          {teamMembers.map((member, index) => (
            <div className="card" key={index}>
              <img src={member.img} alt={`${member.name}'s avatar`} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
      </div>

      <div>
        {/* Sección de ¿Qué ofrece NIV? */}
        <div className="guild-info">
          <div className="info-text">
            <h2>¿Qué ofrece NIV?</h2>
            <ul>
              <li><strong>Enfoque en Competencia PvP y PvE:</strong> Nivirum Club es un clan europeo centrado en dominar batallas y asedios PvP a gran escala, participando también en PvE para preparar a sus miembros.</li>
              <li><strong>Liderazgo Colaborativo:</strong> Todos los miembros participan en el proceso de liderazgo, con una estructura que permite una toma de decisiones ágil.</li>
              <li><strong>Comunidad y Mentoría:</strong> Fomentamos un ambiente de camaradería y ofrecemos mentoría para ayudar a los nuevos miembros a integrarse y mejorar sus habilidades.</li>
              <li><strong>Recursos y Alianzas:</strong> Proporcionamos guías y recursos valiosos, y formamos alianzas estratégicas con otros clanes para fortalecer nuestra posición en PvP.</li>
              <li><strong>Política de Cero Toxicidad:</strong> Mantenemos una estricta política contra el comportamiento tóxico, promoviendo un entorno de respeto y cooperación.</li>
              <li><strong>Integración de Nuevos Miembros:</strong> Facilitamos la adaptación de los nuevos con mentoría y recursos compartidos, asegurando su rápido desarrollo dentro del clan.</li>
            </ul>
          </div>
          <div className="info-image">
            <img src="./publicguildinformation/600px-Timely_Deliverance.png" alt="Carriage with dark theme" />
          </div>
        </div>

        {/* Sección de Objetivos */}
        <div className="guild-info">
          <div className="info-image">
            <img src="./publicguildinformation/Corvid_Castigator-768x768.png" alt="Clan Objectives" />
          </div>
          <div className="info-text">
            <h2>¿Qué objetivos tiene NIV?</h2>
            <ul>
              <li><strong>Dominio en PvP:</strong> Nivirum Club busca dominar en los campos de batalla y asedios en Ashes of Creation.</li>
              <li><strong>Competencia de Alto Nivel:</strong> Sobresalir enfrentándose a otros clanes con estrategia y coordinación.</li>
              <li><strong>Crecimiento y Mejora Constante:</strong> Entrenamientos regulares y participación en eventos desafiantes.</li>
              <li><strong>Liderazgo en la Comunidad Hispana:</strong> Posicionarse como un referente en la comunidad hispana.</li>
              <li><strong>Fomentar una Comunidad Saludable:</strong> Promover un ambiente de camaradería y respeto.</li>
            </ul>
          </div>
        </div>

        {/* Sección de Requisitos de Admisión */}
        <div className="guild-info">
          <div className="info-text">
            <h2>Requisitos de admisión</h2>
            <ul>
              <li><strong>Edad:</strong> Ser mayor de 18 años.</li>
              <li><strong>Compromiso:</strong> Participar en eventos y actividades según tu horario.</li>
              <li><strong>Habilidad:</strong> Capacidad de participar en PvP de alto nivel y mejorar continuamente.</li>
              <li><strong>Comunicación:</strong> Uso obligatorio de Discord durante actividades del clan.</li>
              <li><strong>Mentalidad:</strong> Alinearse con los valores del clan: respeto, cooperación y excelencia.</li>
            </ul>
          </div>
          <div className="info-image">
            <img src="./publicguildinformation/600px-Stillstone_Reliquary.png" alt="Admission Requirements" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicGuildInformation;



