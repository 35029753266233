import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GearPlanner from './components/GearPlanner/GearPlanner';
import PublicGuildInformation from './components/PublicGuildInformation/PublicGuildInformation';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {

  // Asegúrate de ejecutar este script después de que el DOM esté completamente cargado
  document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.static-header');
    const root = document.documentElement;
    root.style.setProperty('--header-height', `${header.offsetHeight}px`);
  });


  return (
    <Router>
      <div id="root">
        
        <header className="static-header">
          <div className="header">
            <Header />
          </div>
        </header>

        <main>
          <Routes className="main-body">
            <Route path="/" element={<Home />} />
            <Route path="/GearPlanner" element={<GearPlanner />} />
            <Route path="/PublicGuildInformation" element={<PublicGuildInformation />} />
            {/* Añade más rutas según tus componentes */}
          </Routes>
        </main>

        <Footer />
      
      </div>
    </Router>
  );
}

export default App;