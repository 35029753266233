import React from 'react';
import './GearPlanner.css'; // Archivo CSS para estilo personalizado.

const GearPlanner = () => {
    const items = [
      { id: 1, nombre: 'Helmet', img: '/gearplanner/TUI_Icon_CM_Head_Empty_64.webp' },
      { id: 2, nombre: 'Chest', img: '/gearplanner/TUI_Icon_CM_Chest_Empty_64.webp' },
      { id: 3, nombre: 'Wrists', img: '/gearplanner/TUI_Icon_CM_Wrists_Empty_64.webp' },
      { id: 4, nombre: 'Hands', img: '/gearplanner/TUI_Icon_CM_Hands_Empty_64.webp' },
      { id: 5, nombre: 'Belt', img: '/gearplanner/TUI_Icon_CM_Belt_Empty_64.webp' },
      { id: 6, nombre: 'Legs', img: '/gearplanner/TUI_Icon_CM_Legs_Empty_64.webp' },
      { id: 7, nombre: 'Feet', img: '/gearplanner/TUI_Icon_CM_Feet_Empty_64.webp' },
      { id: 8, nombre: 'PrimaryMeleeWeapon', img: '/gearplanner/TUI_Icon_CM_PrimaryMeleeWeapon_Empty_64.webp' },
      { id: 9, nombre: 'SecondaryMeleeWeapon', img: '/gearplanner/TUI_Icon_CM_SecondaryMeleeWeapon_Empty_64.webp' },
      { id: 10, nombre: 'PrimaryRangedWeapon', img: '/gearplanner/TUI_Icon_CM_PrimaryRangedWeapon_Empty_64.webp' },
      { id: 11, nombre: 'SecondaryRangedWeapon', img: '/gearplanner/TUI_Icon_CM_SecondaryRangedWeapon_Empty_64.webp' },
      { id: 12, nombre: 'Shoulders', img: '/gearplanner/TUI_Icon_CM_Shoulders_Empty_64.webp' },
      { id: 13, nombre: 'Back', img: '/gearplanner/TUI_Icon_CM_Back_Empty_64.webp' },
      { id: 14, nombre: 'Earring', img: '/gearplanner/TUI_Icon_CM_Earring_Empty_64.webp' },
      { id: 15, nombre: 'Earring', img: '/gearplanner/TUI_Icon_CM_Earring_Empty_64.webp' },
      { id: 16, nombre: 'Necklace', img: '/gearplanner/TUI_Icon_CM_Necklace_Empty_64.webp' },
      { id: 17, nombre: 'Ring', img: '/gearplanner/TUI_Icon_CM_Ring_Empty_64.webp' },
      { id: 18, nombre: 'Ring', img: '/gearplanner/TUI_Icon_CM_Ring_Empty_64.webp' },
    ];
  
    return (
        <div className="equipo-layout">
          <div className="left-column">
            {items.slice(0, 7).map((item) => (
              <div key={item.id} className="slot">
                <img src={item.img} alt={item.nombre} className="item-image" />
                <div className="tooltip">{item.tooltip}</div>
              </div>
            ))}
          </div>
    
          <div className="bottom-row">
            {items.slice(7, 11).map((item) => (
              <div key={item.id} className="slot">
                <img src={item.img} alt={item.nombre} className="item-image" />
                <div className="tooltip">{item.tooltip}</div>
              </div>
            ))}
          </div>
    
          <div className="right-column">
            {items.slice(11).map((item) => (
              <div key={item.id} className="slot">
                <img src={item.img} alt={item.nombre} className="item-image" />
                <div className="tooltip">{item.tooltip}</div>
              </div>
            ))}
          </div>
        </div>
      );
    };

export default GearPlanner;
