// components/Home/Home.jsx
import React from 'react';
import './Home.css';

const Home = () => {

    const handleButtonClick = () => {
        window.location.href = 'https://discord.com/invite/JpYdfVc9bP';
    };

    return (
      <div className="home">
        <video autoPlay loop muted className="background-video">
          <source src="./home/aoc_video_home.mp4" type="video/mp4" />
          Tu navegador no soporta videos HTML5.
        </video>
        <div className="overlay">
          <h1>NIVIRUM CLUB</h1>
          <p>
            CLAN HARDCORE – PvX – COMPETITIVO – EU – Ashes of Creation
          </p>
          <button className="cta-button" onClick={handleButtonClick}>ÚNETE</button>
        </div>
      </div>
    );
  };

export default Home;
