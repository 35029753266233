import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
        {/* Logo y título dentro del enlace */}
        <Link to="/" className="logo-link">
          <img src="./Logo_version_2-transformed.png" alt="Logo" className="logo-image" />
          <h1 className="logo-text">NIVIRUM CLUB</h1>
        </Link>
        <nav className="nav">
          <Link to="/PublicGuildInformation" className="nav-item">Information</Link>
          <Link to="/GuildManagement" className="nav-item">Management</Link>
          <Link to="/GearPlanner" className="nav-item">GearPlanner</Link>
          <Link to="/Guides" className="nav-item">Guides</Link>
          <Link to="/PartyFinder" className="nav-item">PartyFinder</Link>
        </nav>
      </div>
      <div className="header-right">
        <input type="text" placeholder="Search" className="search-bar" />
        <button className="btn discord-btn">Login</button>
      </div>
    </header>
  );
};

export default Header;

